<template>
  <div class="tw-flex tw-justify-center tw-container tw-w-full tw-m-auto ">
    <div class="tw-m-0 sm:tw-m-10 tw-flex tw-justify-center tw-flex-1 md:tw-gap-20">
      <div class="md:tw-w-1/2 lg:tw-w-1/2 tw-w-full tw-mx-4 md:tw-mx-0 tw-mt-0 md:tw-mt-16">
        <h2 class=" tw-text-2xl md:tw-text-4xl tw-text-gray-900 tw-font-semibold tw-mt-12 md:tw-mt-0">{{ $t('signUp.signupTalent') }}</h2>
        <talentSignup />
      </div>
      <div class="md:tw-w-1/2 lg:tw-w-1/2 tw-text-center md:tw-flex tw-justify-center tw-items-start tw-hidden ">
        <img
          class="tw-w-auto tw-max-h-[536.82px] tw-object-contain"
          src="@/assets/images/signup-design-min.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import talentSignup from "./talent.vue";

export default {
  components: {
    talentSignup,
  },
};
</script>
